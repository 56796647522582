
import Vue from "vue"
import TableFilterSearch
  from "@/components/TableFilterSearch.vue"

export default Vue.extend({
  name: "WeaponsListTable",

  components: {
    TableFilterSearch
  },

  props: {
    weapons: { type: Array, required: true },
    loading: { type: Boolean, default: false }
  },

  data: function() {
    return {
      selection: [],
      search: "",
      headers: [{
        value: "name",
        text: this.$t("weaponsListTableColumnNameLabel")
      }, {
        value: "number",
        text: this.$t("weaponsListTableColumnNumberLabel")
      }, {
        value: "category",
        text: this.$t("weaponsListTableColumnCategoryLabel")
      }, {
        value: "distance",
        text: this.$t("weaponsListTableColumnDistanceLabel")
      },
      // {
      //   value: "condition",
      //   text: this.$t("weaponsListTableColumnConditionLabel")
      // },
      {
        value: "actions",
        sortable: false,
        align: "right"
      }]
    }
  },

  computed: {
    hasSelection(): boolean {
      return this.selection.length > 0
    }
  },

  methods: {
    weaponsCreateDialogOpen(): void {
      this.$emit("weaponsCreateDialogOpen")
    },

    weaponsEditDialogOpen(weapon): void {
      this.$emit("weaponsEditDialogOpen", weapon)
    },

    weaponsRemoveOne(weapon): void {
      this.$emit("weaponsRemoveOne", weapon)
    },

    weaponsRemoveMany(weapons): void {
      this.$emit("weaponsRemoveMany", weapons)
    }
  }
})
