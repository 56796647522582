
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import WeaponsListTable from "./WeaponsListTable.vue"
import WeaponsCreateDialog from "./WeaponsCreateDialog.vue"
import WeaponsEditDialog from "./WeaponsEditDialog.vue"
import Confirm from "@/components/Confirm.vue"

export default Vue.extend({
  name: "WeaponsListScreen",

  components: {
    WeaponsListTable,
    WeaponsCreateDialog,
    WeaponsEditDialog,
    Confirm
  },

  data: () => ({
    weaponsCreateDialogShown: false,
    weaponsEditDialogShown: false,
    weaponsEditDialogWeapon: {}
  }),

  computed: {
    ...mapState("weapons", {
      weaponsStateListIsLoading: "listIsLoading",
      weaponsStateList: "list",
      weaponsStateRemoveOneIsLoading: "removeOneIsLoading",
      weaponsStateRemoveManyIsLoading: "removeManyIsLoading"
    }),
    loading(): boolean {
      return (
        this.weaponsStateListIsLoading ||
        this.weaponsStateRemoveOneIsLoading ||
        this.weaponsStateRemoveManyIsLoading
      )
    }
  },

  created() {
    this.weaponsActionsList()
  },

  methods: {
    ...mapActions("weapons", {
      weaponsActionsList: "list",
      weaponsActionsRemoveOne: "removeOne",
      weaponsActionsRemoveMany: "removeMany",
    }),

    weaponsCreateDialogOpen(): void {
      this.weaponsCreateDialogShown = true
    },

    weaponsEditDialogOpen(weapon): void {
      this.weaponsEditDialogShown = true
      this.weaponsEditDialogWeapon = weapon
    },

    async weaponsRemoveOne(weapon): Promise<void> {
      if(!await this.$refs.confirm.dangerous(
        this.$t("weaponsRemoveOneConfirmation", {
          weaponsName: weapon.name
        })
      )) return

      try {
        await this.weaponsActionsRemoveOne(weapon)
        this.$success(this.$t("weaponsActionsRemoveOneSuccess", {
          weaponsName: weapon.name
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    async weaponsRemoveMany(weapons): Promise<void> {
      const count = weapons.length

      if(!await this.$refs.confirm.dangerous(
        this.$t("weaponsRemoveManyConfirmation", {
          weaponsCount: count
        })
      )) return

      try {
        await this.weaponsActionsRemoveMany(weapons)
        this.$success(this.$t("weaponsActionsRemoveManySuccess", {
          weaponsCount: count
        }))
      } catch(e) {
        this.$error(e.message)
      }
    }
  }
})
