
import Vue from "vue"
import { mapState } from "vuex"
import weaponsStub from "./weapons.stub"

export default Vue.extend({
  name: "WeaponsForm",

  props: {
    value: { type: Object, default: () => weaponsStub }
  },

  computed: mapState("weapons", {
    weaponsStateCategories: "categories",
    weaponsStateConditions: "conditions"
  }),

  watch: {
    value: {
      deep: true,
      handler(data): void {
        data.distance = parseInt(data.distance)
        this.$emit("input", data)
      }
    }
  },

  methods: {
    submit(cb): void {
      this.$refs.localForm.validate()
        ? cb()
        : this.$error(this.$t("validationError"))
    },

    resetFields(): void {
      this.$refs.localForm.reset()
    }
  }
})
